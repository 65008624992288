import React, { useState, useCallback } from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import {
	arrowButton,
	categoryTxt,
	container,
	eyeCatchBgFdm,
	eyeCatchBgLisa,
	eyeCatchImage,
	newsCard,
	pageButton,
} from './Category.module.scss'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { NewsHeader } from '../components/domains/news'
import { ArrowLeft, ArrowRight } from '../components/ui/Icon'

const perPage = 9

const EyeCatchImageBg: React.FC<{ category: string }> = ({ category }) => {
	return (
		<div
			className={classnames(
				category === 'FDMリリース' ? eyeCatchBgFdm : eyeCatchBgLisa,
				'bg-center bg-no-repeat bg-contain rounded-l border'
			)}
		/>
	)
}

export default function Category({ data, pageContext, ...props }) {
	const [currentPage, setCurentPage] = useState(1)

	const { location } = props as PageProps
	let category: string

	if (pageContext.category === 'press') {
		category = 'プレスリリース'
	} else if (pageContext.category === 'info') {
		category = 'お知らせ'
	} else {
		category = 'ニュース'
	}

	const totalPage = Math.ceil(data.allWpPost.edges.length / perPage)

	const handleClickPage = useCallback((pageNum: number) => {
		setCurentPage(pageNum)
	}, [])

	const handleClickPrevious = useCallback(() => {
		if (currentPage <= 1) return

		setCurentPage((prev) => prev - 1)
	}, [currentPage])

	const handleClickNext = useCallback(() => {
		if (currentPage >= totalPage) return

		setCurentPage((prev) => prev + 1)
	}, [currentPage, totalPage])

	const posts = data.allWpPost.edges.filter((data, i: number) => {
		return i < currentPage * perPage && i + (currentPage - 1) * 9 < currentPage * perPage && data
	})

	return (
		<Layout location={[{ name: `ニュース | ${category}`, path: `/news/${pageContext.category}` }]}>
			<SEO title={category} description="" />
			<NewsHeader location={location} />
			<div className={container}>
				<div className="flex flex-wrap">
					{posts.map((data) => {
						const post = data.node
						const image =
							post.featuredImage !== null && post.featuredImage.node.localFile.childImageSharp.gatsbyImageData

						return (
							<Link
								key={post.id}
								to={`/news/${post.id}`}
								className={classnames(newsCard, 'flex flex-col hover:opacity-70 duration-300')}
							>
								{post.featuredImage === null ? (
									<EyeCatchImageBg category={category} />
								) : (
									<GatsbyImage
										image={image}
										alt={`${post.title}のアイキャッチ画像`}
										className={classnames(eyeCatchImage, 'object-contain rounded-l')}
										objectFit="contain"
										objectPosition="center"
									/>
								)}
								<div className="flex justify-start items-center mt-5">
									<span
										className={classnames(categoryTxt, 'py-1 px-2 w-24 text-center text-secondary bg-gray rounded-big')}
									>
										{category}
									</span>
									<small className="ml-4 text-xs">{post.date}</small>
								</div>
								<h3 className="overflow-hidden mt-5 text-sm font-bold whitespace-pre-wrap">
									{post.title.substr(0, 60)}
									{post.title.length > 60 ? '...' : null}
								</h3>
							</Link>
						)
					})}
				</div>
				<div className="flex justify-center mx-auto mt-14">
					<button
						className={classnames('mr-5', arrowButton)}
						disabled={currentPage === 1}
						onClick={handleClickPrevious}
					>
						<ArrowLeft />
					</button>
					{currentPage === 1
						? [...Array(3)].map((_, i) => {
								if (currentPage + i > totalPage) return null

								return (
									<button
										key={currentPage + i}
										className={classnames(
											'py-2 px-3.5 mx-2 w-10 h-10 text-sm rounded-circle border',
											currentPage === currentPage + i ? pageButton : 'bg-secondary'
										)}
										onClick={() => handleClickPage(currentPage + i)}
									>
										{currentPage + i}
									</button>
								)
						  })
						: [...Array(3)].map((_, i) => {
								if (currentPage + i - 1 > totalPage) return null

								return (
									<button
										key={currentPage + i - 1}
										className={classnames(
											'py-2 px-3.5 mx-2 w-10 h-10 text-sm rounded-circle border',
											currentPage === currentPage + i - 1 ? pageButton : 'bg-secondary'
										)}
										onClick={() => handleClickPage(currentPage + i - 1)}
									>
										{currentPage + i - 1}
									</button>
								)
						  })}
					{currentPage + 1 >= totalPage ? null : (
						<p className="flex items-center">
							<span className="tracking-tighter">・・・</span>
							<button
								className={classnames('py-2 px-3.5 mx-2 w-10 h-10 text-sm bg-secondary rounded-circle border')}
								onClick={() => handleClickPage(totalPage)}
							>
								{totalPage}
							</button>
						</p>
					)}
					<button
						className={classnames('ml-5', arrowButton)}
						disabled={currentPage === totalPage}
						onClick={handleClickNext}
					>
						<ArrowRight />
					</button>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query ($category: String!) {
		allWpPost(
			filter: { categories: { nodes: { elemMatch: { slug: { eq: $category } } } } }
			sort: { fields: date, order: DESC }
		) {
			edges {
				node {
					date(formatString: "YYYY.MM.DD")
					id
					excerpt
					title
					featuredImage {
						node {
							id
							sizes
							sourceUrl
							srcSet
							caption
							localFile {
								id
								childImageSharp {
									id
									gatsbyImageData(layout: CONSTRAINED, height: 168, quality: 100)
								}
							}
						}
					}
					categories {
						nodes {
							id
							name
							slug
						}
					}
				}
			}
		}
	}
`
